import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { useGetProfileQuery } from '../../redux/api/montageApi';
import { selectShouldShowLmLogin } from '../../redux/selectors';
import InstallationDetailsPage from './InstallationDetailsPage';
import InstallationPage from './InstallationPage';
import ServicePortalLayout from './ServicePortalLayout';
import LMLoginPage from '../LMLoginPage';

const MainPage = () => {
  return (
    <ServicePortalLayout>
      <Typography variant="h3">Service Portal</Typography>
    </ServicePortalLayout>
  );
};

export const ServicePortal = () => {
  const shouldShowLmLogin = useSelector(selectShouldShowLmLogin);
  // Query used just to verify that user is logged in
  const { refetch } = useGetProfileQuery();

  if (shouldShowLmLogin) {
    return <LMLoginPage withIframe onLoad={refetch} />;
  }
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="installation" element={<InstallationPage />} />,
      <Route
        path="installation/:projectId/:installationId"
        element={<InstallationDetailsPage />}
      />
      ,
    </Routes>
  );
};

export default ServicePortal;
